@import "~slick-carousel/slick/slick-theme.css";
@import "~slick-carousel/slick/slick.css"; /* Add Tailwind's base, components, and utilities */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css or App.css */


body {
  background-color: #111827;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#webgl-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 98vw;  /* Ensure it uses the full viewport width */
  height: 100vh; /* Ensure it uses the full viewport height */
  z-index: -1;   /* Keep it behind other content */
  overflow: hidden; /* Prevent any overflow if the canvas grows beyond the screen */
}



